import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Img from "../images/wth.gif";

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="Uh Oh..." flex={true}>
      <div className="flex flex-col text-center justify-center items-center">
        <p className="my-3 text-cyan-500 font-bold text-xl">
          So........this page doesn't exist.
        </p>
        <img
          src={Img}
          alt="wait..what?"
          className="rounded-md w-[330px] h-[225px] lg:w-[490px] lg:h-[275px]"
        />
        <p className="my-3 text-gray-500 text-sm">How'd you get here?</p>
        <Link to="/">
          <button className="rounded-lg font-extrabold border-2 border-cyan-500 bg-cyan-500 px-3 py-1 text-white hover:bg-black hover:text-cyan-500">
            Go home
          </button>
        </Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
